// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-designer-template-js": () => import("/opt/build/repo/src/templates/designerTemplate.js" /* webpackChunkName: "component---src-templates-designer-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/opt/build/repo/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-awards-js": () => import("/opt/build/repo/src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-baby-js": () => import("/opt/build/repo/src/pages/baby.js" /* webpackChunkName: "component---src-pages-baby-js" */),
  "component---src-pages-colored-stones-js": () => import("/opt/build/repo/src/pages/colored-stones.js" /* webpackChunkName: "component---src-pages-colored-stones-js" */),
  "component---src-pages-contact-us-js": () => import("/opt/build/repo/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-designer-benchmark-js": () => import("/opt/build/repo/src/pages/designer/benchmark.js" /* webpackChunkName: "component---src-pages-designer-benchmark-js" */),
  "component---src-pages-designer-forevermark-js": () => import("/opt/build/repo/src/pages/designer/forevermark.js" /* webpackChunkName: "component---src-pages-designer-forevermark-js" */),
  "component---src-pages-designer-marco-bicego-js": () => import("/opt/build/repo/src/pages/designer/marco-bicego.js" /* webpackChunkName: "component---src-pages-designer-marco-bicego-js" */),
  "component---src-pages-designer-michele-js": () => import("/opt/build/repo/src/pages/designer/michele.js" /* webpackChunkName: "component---src-pages-designer-michele-js" */),
  "component---src-pages-designer-penny-preville-js": () => import("/opt/build/repo/src/pages/designer/penny-preville.js" /* webpackChunkName: "component---src-pages-designer-penny-preville-js" */),
  "component---src-pages-designer-robertocoin-js": () => import("/opt/build/repo/src/pages/designer/robertocoin.js" /* webpackChunkName: "component---src-pages-designer-robertocoin-js" */),
  "component---src-pages-designer-seiko-js": () => import("/opt/build/repo/src/pages/designer/seiko.js" /* webpackChunkName: "component---src-pages-designer-seiko-js" */),
  "component---src-pages-designer-tudor-js": () => import("/opt/build/repo/src/pages/designer/tudor.js" /* webpackChunkName: "component---src-pages-designer-tudor-js" */),
  "component---src-pages-diamond-calculator-js": () => import("/opt/build/repo/src/pages/diamond-calculator.js" /* webpackChunkName: "component---src-pages-diamond-calculator-js" */),
  "component---src-pages-diamond-education-js": () => import("/opt/build/repo/src/pages/diamond-education.js" /* webpackChunkName: "component---src-pages-diamond-education-js" */),
  "component---src-pages-engagement-js": () => import("/opt/build/repo/src/pages/engagement.js" /* webpackChunkName: "component---src-pages-engagement-js" */),
  "component---src-pages-estate-js": () => import("/opt/build/repo/src/pages/estate.js" /* webpackChunkName: "component---src-pages-estate-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fashion-js": () => import("/opt/build/repo/src/pages/fashion.js" /* webpackChunkName: "component---src-pages-fashion-js" */),
  "component---src-pages-financing-js": () => import("/opt/build/repo/src/pages/financing.js" /* webpackChunkName: "component---src-pages-financing-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jewelry-repair-js": () => import("/opt/build/repo/src/pages/jewelry-repair.js" /* webpackChunkName: "component---src-pages-jewelry-repair-js" */),
  "component---src-pages-locations-js": () => import("/opt/build/repo/src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-our-diamond-js": () => import("/opt/build/repo/src/pages/our-diamond.js" /* webpackChunkName: "component---src-pages-our-diamond-js" */),
  "component---src-pages-our-history-js": () => import("/opt/build/repo/src/pages/our-history.js" /* webpackChunkName: "component---src-pages-our-history-js" */),
  "component---src-pages-our-team-js": () => import("/opt/build/repo/src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-anniversary-js": () => import("/opt/build/repo/src/pages/products/anniversary.js" /* webpackChunkName: "component---src-pages-products-anniversary-js" */),
  "component---src-pages-products-bracelets-js": () => import("/opt/build/repo/src/pages/products/bracelets.js" /* webpackChunkName: "component---src-pages-products-bracelets-js" */),
  "component---src-pages-products-colored-gemstones-js": () => import("/opt/build/repo/src/pages/products/colored-gemstones.js" /* webpackChunkName: "component---src-pages-products-colored-gemstones-js" */),
  "component---src-pages-products-earrings-js": () => import("/opt/build/repo/src/pages/products/earrings.js" /* webpackChunkName: "component---src-pages-products-earrings-js" */),
  "component---src-pages-products-engagement-js": () => import("/opt/build/repo/src/pages/products/engagement.js" /* webpackChunkName: "component---src-pages-products-engagement-js" */),
  "component---src-pages-products-estate-js": () => import("/opt/build/repo/src/pages/products/estate.js" /* webpackChunkName: "component---src-pages-products-estate-js" */),
  "component---src-pages-products-fashion-js": () => import("/opt/build/repo/src/pages/products/fashion.js" /* webpackChunkName: "component---src-pages-products-fashion-js" */),
  "component---src-pages-products-index-js": () => import("/opt/build/repo/src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-necklace-js": () => import("/opt/build/repo/src/pages/products/necklace.js" /* webpackChunkName: "component---src-pages-products-necklace-js" */),
  "component---src-pages-products-rings-js": () => import("/opt/build/repo/src/pages/products/rings.js" /* webpackChunkName: "component---src-pages-products-rings-js" */),
  "component---src-pages-products-specials-js": () => import("/opt/build/repo/src/pages/products/specials.js" /* webpackChunkName: "component---src-pages-products-specials-js" */),
  "component---src-pages-products-underwoods-designs-js": () => import("/opt/build/repo/src/pages/products/underwoods-designs.js" /* webpackChunkName: "component---src-pages-products-underwoods-designs-js" */),
  "component---src-pages-products-womans-bands-js": () => import("/opt/build/repo/src/pages/products/womans-bands.js" /* webpackChunkName: "component---src-pages-products-womans-bands-js" */),
  "component---src-pages-registry-js": () => import("/opt/build/repo/src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-returns-js": () => import("/opt/build/repo/src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-pages-rolex-js": () => import("/opt/build/repo/src/pages/rolex.js" /* webpackChunkName: "component---src-pages-rolex-js" */),
  "component---src-pages-services-js": () => import("/opt/build/repo/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shipping-js": () => import("/opt/build/repo/src/pages/shipping.js" /* webpackChunkName: "component---src-pages-shipping-js" */),
  "component---src-pages-terms-of-service-js": () => import("/opt/build/repo/src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-thank-you-js": () => import("/opt/build/repo/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-trading-selling-js": () => import("/opt/build/repo/src/pages/trading-selling.js" /* webpackChunkName: "component---src-pages-trading-selling-js" */),
  "component---src-pages-underwoods-designs-js": () => import("/opt/build/repo/src/pages/underwoods-designs.js" /* webpackChunkName: "component---src-pages-underwoods-designs-js" */),
  "component---src-pages-watch-repair-js": () => import("/opt/build/repo/src/pages/watch-repair.js" /* webpackChunkName: "component---src-pages-watch-repair-js" */),
  "component---src-pages-watches-js": () => import("/opt/build/repo/src/pages/watches.js" /* webpackChunkName: "component---src-pages-watches-js" */),
  "component---src-pages-wedding-anniversary-js": () => import("/opt/build/repo/src/pages/wedding-anniversary.js" /* webpackChunkName: "component---src-pages-wedding-anniversary-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

